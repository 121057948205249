import React from "react";

import Placeholder, { Rect } from "components/Placeholder";

const DispensaryHeaderFulfillmentPlaceholder: React.FC = () => (
  <div className="h-[122px]">
    <Placeholder width="100%" height="56" className="rounded-full">
      <Rect rx="3" ry="3" width="100%" height="100%" />
    </Placeholder>
  </div>
);

export default DispensaryHeaderFulfillmentPlaceholder;
